export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage?: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;

  convertKitFormId?: string;

  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  googleAnalyticsId?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
  /**
   * Shows all post tags in main index view and post view if true
   * Otherwise only shows first (primary) tag
   */
  showAllTags: boolean;
}

const config: WebsiteConfig = {
  title: 'Worth a Travel',
  description: 'The blog about my travels and findings during them',
  coverImage: '',
  logo: '',
  lang: 'en',
  siteUrl: 'https://worthatravel.com',
  facebook: '',
  twitter: '',
  showSubscribe: false,
  mailchimpAction:'',
  mailchimpName: '',
  mailchimpEmailFieldName: '',
  convertKitFormId: '2679869',
  googleSiteVerification: 'YSPJIzMffumKURcXCsT3ZQxmwR8ShBkCXSz3-3GOmk0',
  googleAnalyticsId: 'G-X3XXDLS6MB',
  footer: '',
  showAllTags: true,
};

export default config;
